import React, { Component } from 'react';
import link from './../assets/link.gif';
import PreloadImage from 'react-preload-image';

class ProjectImages extends Component {
    constructor(props) {
        super(props);
        this.state = {dataSet: this.props.data }
    }
    
    componentDidMount() {
        this.setState({ isLoading: true });
    }

    render() {
        const { dataSet } = this.state;
        let images = dataSet.map((image, index) => {
            let imageUrl = image.full_image_url;
            let url = image.url;
            return (
                <div className="attachedImage" key={index}>
                    { url !== "" 
                        ? ( <a href={url} target="_blank" rel="noopener noreferrer">
                                <PreloadImage
                                    className="loading"
                                    src={imageUrl}
                                    lazy
                                    duration="1000ms"
                                />
                            </a>
                        )
                        : ( <PreloadImage
                                className="loading"
                                src={imageUrl}
                                lazy
                                duration="1000ms"
                            />
                        )
                    }
                        
                    { url !== "" &&
                        <a href={url} target="_blank" rel="noopener noreferrer"><span><img src={link} alt="" /></span></a>
                    }
                </div>
            )
        });
        return (
            <div className="attachedImages">
                { images }
            </div>
        );
    }
}

export default ProjectImages;