import React, { Component } from 'react';
import PreloadImage from 'react-preload-image';
import Loading from './Loading';

const API = 'https://willcer.com/admin/wp-json/wp/v2/';
const QUERY = 'client?_embed&orderby=title&order=asc&per_page=100';

class Clients extends Component {
    constructor(props) {
        super(props);
        this.state = { dataSet: [], isLoading: true }
    }
    
    componentDidMount() {
        fetch(API+QUERY) // REMOVE LANG DEPENDENT
            .then(response => {
                if  (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Data unavailbale.');
                }
            })
        .then(response => { this.setState({ dataSet: response, isLoading: false }) })
        .catch(error => this.setState({ error, isLoading: false }));
    }

    truncateContent(content, numWords) {
        let string = content.toString();
        return string.split(" ").splice(0,numWords).join(" ")+"...";
    }

    render() {
        const { dataSet, isLoading, error } = this.state;
        if (error) {
            return <p>{error.message}</p>;
        }
        if (isLoading) {
            return <Loading />;
        }
        let clients = dataSet.map((client, index) => {
            function fPostHasImage(client, mode) {
                if (client.featured_media > 0 && client.featured_media !== undefined){
                    let sourceUrl = client._embedded['wp:featuredmedia'][0].media_details.sizes.medium.source_url;
                    let imageAlt = client._embedded['wp:featuredmedia'][0].media_details.sizes.medium.alt;
                    if (mode === "sourceUrl")
                        return  sourceUrl;
                    else if (mode === "alt")
                        return  imageAlt;  
                }
            }
            let imageUrl = fPostHasImage(client, "sourceUrl");
            let title = client.title.rendered;
            return (
                <div className="client" key={index}>
                    
                     <div className="banner">
                        <PreloadImage
                            className="loading"
                            src={imageUrl}
                            lazy
                            duration="1000ms"
                        />
                    </div>
                        <div className="name">{title}</div>
                    
                </div>
            )
        });
        return (
            <div className="clientBlock">
                 <div className="sectionTitle">
                        <div className="anchor"><u>Clients</u></div>
                </div>
                <div className="clients">
                    { clients }
                </div>
            </div>
        );
    }
}

export default Clients;