import React, { Component } from 'react';
import ProjectImages from './ProjectImages';
import ProjectsMore from './ProjectsMore';
import Loading from './../components/Loading';
import PreloadImage from 'react-preload-image';
import i18n from '../i18n';
import { Helmet } from 'react-helmet-async';

const API = 'https://willcer.com/admin/wp-json/wp/v2/';
const QUERY = 'projects?_embed&filter[orderby]=menu_order&order=asc';

class Project extends Component {
    constructor(props) {
        super(props);
        this.state = { slug: this.props.slug, dataSet: [], isLoading: false}
    }
    
    componentDidMount() {
        window.scrollTo(0, 0);
        this.setState({ isLoading: true });
        let addQuery = "";
        if (this.state.slug !== "")
            addQuery = "&slug="+this.state.slug;
        fetch(API+QUERY+addQuery)
            .then(response => {
                if  (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Data unavailbale.');
                }
            })
        .then(response => { this.setState({ dataSet: response, isLoading: false }) })
        .catch(error => this.setState({ error, isLoading: false }));
    }

    render() {
        const { dataSet, isLoading, error } = this.state;
        if (error) {
            return <p>{error.message}</p>;
        }
        if (isLoading) {
            return <Loading />;
        }
        let projects = dataSet.map((project, index) => {
            function fPostHasImage(project, mode) {
                if (project.featured_media > 0){
                    let sourceUrl = project._embedded['wp:featuredmedia'][0].media_details.sizes.full.source_url;
                    let imageAlt = project._embedded['wp:featuredmedia'][0].media_details.sizes.full.alt;
                    if (mode === "sourceUrl")
                        return  sourceUrl;
                    else if (mode === "alt")
                        return  imageAlt;  
                }
            }
            let imageUrl = fPostHasImage(project, "sourceUrl");
            let title = project.title.rendered;
            let content = project.content.rendered;
            let excerpt = project.excerpt.rendered;
            let lang = i18n.language.substr(0,2); 
            if (lang === "fr") {
                title = project.acf.title_fr;
                content = project.acf.content_fr;
                excerpt = project.acf.excerpt_fr;
            }
            return (
                <div className="project" key={index}>
                    <Helmet>
                        <title>{i18n.t('meta site title')} {i18n.t('meta site title work')} – {title}</title>
                    </Helmet>
                    <div className="sectionTitle">
                        <div className="anchor"><u>{title}</u></div>
                    </div>
                     <div className="banner">
                        <PreloadImage
                            className="loadingBanner"
                            src={imageUrl}
                            lazy
                            duration="1000ms"
                        />
                    </div>
                    <div className="content single">
                        <div className="text">
                            <div className="excerpt" dangerouslySetInnerHTML={{__html: excerpt}}></div>
                            <p dangerouslySetInnerHTML={{__html: content}}></p>
                        </div>
                        <ProjectImages  data={project.acf_photo_gallery} />
                    </div>
                    <div className="spacerHalf"></div>
                    <ProjectsMore excludeId={project.id} />
                </div>
            )
        });
        return (
            <div className="work">
                <div className="projects">
                    { projects }
                </div>
            </div>
    );
    }
}

export default Project;