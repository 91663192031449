import React, { Component } from 'react';
import Navigation from '../components/Navigation';
import Logo from '../components/Logo';
import Blog from '../components/Blog';
import BlogPost from '../components/BlogPost';
import ContactBanner from '../components/ContactBanner';
import Footer from './../components/Footer';
import i18n from '../i18n';
import { Helmet } from 'react-helmet-async';

class BlogPage extends Component {

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        let pathArray = window.location.pathname.split('/');
        let slug = pathArray[2];
        if (slug === undefined || slug === "") {
            return (
                <div>
                    <Helmet>
                        <title>{i18n.t('meta site title')} {i18n.t('meta site title blog')}</title>
                    </Helmet>
                    <div className="wrapper">
                        <Navigation />
                        <Logo />
                    </div>
                    <div className="wrapperSqueeze">
                        <div className="blogPage">
                            <div className="sectionTitle">
                                <div className="anchor" id="blog">{i18n.t('blog')} <span className="notice" >{i18n.t('blog notice')}</span></div>
                                
                            </div>
                            <Blog display="grid" />
                        </div>
                    </div>
                    <ContactBanner />
                    < Footer />
                </div>
            );
        }
        else {
            return (
                <div>
                    <div className="wrapper">
                        <Navigation />
                        <Logo />
                    </div>
                    <div className="wrapperSqueeze">
                        <BlogPost slug={slug} />
                    </div>
                    <ContactBanner />
                    < Footer />
                </div>
            );
        }
    }
}

export default BlogPage;