import React, { Component } from 'react';
import Author from './../components/Author';
import Loading from './Loading';
import i18n from '../i18n';

const API = 'https://willcer.com/admin/wp-json/wp/v2/';
const QUERY = 'pages/?slug=about';

class About extends Component {
    constructor(props) {
        super(props);
        this.state = { dataSet: [], isLoading: true }
    }
    
    componentDidMount() {
        let addLang = "";
        let lang = i18n.language.substr(0,2); 
        if (lang === "fr") {
            addLang = "-fr";
        }
        fetch(API+QUERY+addLang)
            .then(response => {
                if  (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Data unavailbale.');
                }
            })
        .then(response => { this.setState({ dataSet: response, isLoading: false }) })
        .catch(error => this.setState({ error, isLoading: false }));
    }

    truncateContent(content, numWords) {
        let string = content.toString();
        return string.split(" ").splice(0,numWords).join(" ")+"...";
    }

    render() {
        const { dataSet, isLoading, error } = this.state;
        if (error) {
            return <p>{error.message}</p>;
        }
        if (isLoading) {
            return <Loading />;
        }
        let title = dataSet.map((data) => { return ( data.title.rendered )});
        let content = dataSet.map((data) => { return ( data.content.rendered )});
        if (this.props.display === "blurb") {
            return (
                <div className="about">
                    <div className="blockFull">
                        <div className="blockLeft">
                            <div className="anchor" id="about"><u>{title}</u></div>
                            <Author /> 
                        </div>
                        <div className="blockText">
                            <p dangerouslySetInnerHTML={{__html: content}}></p>
                        </div>
                    </div>
                </div>
            );
        }
        else {
            return (
                <div className="about">
                    <div className="blockFull">
                        <div className="blockLeft">
                        <div className="anchor" id="about"><u className="flash">{title}</u></div>
                            <Author/>
                        </div>
                        <div className="blockText">
                            <p dangerouslySetInnerHTML={{__html: content }}></p> 
                        </div>
                    </div>
                    <div className="spacer"></div>
                </div>
            );
        }
    }
}

export default About;