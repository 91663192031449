import React, { Component } from 'react';
import Navigation from '../components/Navigation';
import About from '../components/About';
import Clients from '../components/Clients';
import Blog from '../components/Blog';
import Logo from '../components/Logo';
import Testimonials from '../components/Testimonials';
import ContactBanner from '../components/ContactBanner';
import PreloadImage from 'react-preload-image';
import Footer from '../components/Footer';
import Banner from '../assets/banner15.png';
import i18n from '../i18n';
import { Helmet } from 'react-helmet-async';

class HomePage extends Component {

    componentDidMount() {
        window.scrollTo(0, 0);
        window.addEventListener("scroll", this.fadeQuote);
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.fadeQuote);
    }

    fadeQuote() {
        let increment = -0.8;
        let scrolled = window.scrollY;
        //document.getElementById('myTint').style.cssText = 'opacity:'+ (1 +  Math.round(scrolled/(increment-0.1))/1000)+'; filter: blur('+ (0 +  Math.round(scrolled/(increment+4))/100)+'px);'
        //document.getElementById('myTint').style.cssText = 'opacity:'+ (1 +  Math.round(scrolled/(increment))/1000)+'; filter: blur('+ (0 +  Math.round(scrolled/(increment+3))/100)+'px'
        //ocument.getElementById('myTint').style.cssText = 'opacity:'+ (1 +  Math.round(scrolled/(increment))/1000)+'; '
        
        //document.getElementById('logo').style.cssText = 'transform:  scale(' + Math.round((scrolled*0.02)) + 1+' );'
        /*if (scrolled > window.innerHeight)
            document.getElementById('btnHamburger').style.cssText = 'color: #333; border-color: #333;';
        else   
            document.getElementById('btnHamburger').style.cssText = 'color: #fff; border-color: #fff;';*/
        // transform: translate(0px, ' + Math.round((scrolled*increment)) +'px);
        // opacity:'+ (1 +  Math.round(scrolled/(increment-0.5))/1000)+';
        // filter: blur('+ (0 +  Math.round(scrolled/(increment+2))/100)+'px);
        //document.getElementById('myTint').style.cssText = 'background-color: rgba(0,0,0,'+ (0.1 +  Math.round(scrolled/(increment+1.2))/1000)+');'
        //document.getElementById('myTint').style.cssText = 'filter: blur('+ (0 +  Math.round(scrolled/(increment+2))/100)+'px);'
    }
    
    render() {
        return (
            <div className="home">
                <Helmet> 
                    <title>{i18n.t('meta site title')} {i18n.t('meta site title homepage')}</title>
                </Helmet>
                
                <div className="wrapper" id="myBanner">

                    <Navigation mode="dark" />
                    <Logo loc="home" />
                    <div className="tint" id="myTint">
                        <PreloadImage
                            className="loading"
                            src={Banner}
                            duration="1000ms"
                        />
        
                    </div>
                    <Testimonials />
                </div>
                <div id="fullPage">
                    <div className="wrapperSqueeze">
                        <About display="blurb" />

                        <Clients />
                        <br /><br />
                        {/*<div className="work projectsPage">
                            <div className="sectionTitle">
                                <div className="anchor" id="work"><a href="/work"><u>{i18n.t('digital work')}</u></a></div>
                            </div>
                            <Projects />
                        </div>*/}
                        {/* <div className="blogPage">
                            <div className="sectionTitle">
                                <div className="anchor" id="work"><a href="/blog"><u>{i18n.t('blog')}</u></a> <span className="notice" >{i18n.t('blog notice')}</span></div>
                            </div>
                            <Blog display="grid" />
                        </div> */}
                    </div>
                    <ContactBanner />
                    <Footer />
                </div>
            </div>
        );
    }
}

export default HomePage;