import React, { Component } from 'react';
import Loading from './Loading';
import PreloadImage from 'react-preload-image';
import i18n from '../i18n';

const API = 'https://willcer.com/admin/wp-json/wp/v2/';
const QUERY = 'pages/?_embed&slug=contact-avatar';

class Author extends Component {
    constructor(props) {
        super(props);
        this.state = { dataSet: [], isLoading: false }
    }

    componentDidMount() {
        let addLang = "";
        let lang = i18n.language.substr(0,2); 
        if (lang === "fr") {
            addLang = "-fr";
        }this.setState({ isLoading: true });
        fetch(API+QUERY+addLang)
            .then(response => {
                if  (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Data unavailbale.');
                }
            })
        .then(response => { this.setState({ dataSet: response, isLoading: false }) })
        .catch(error => this.setState({ error, isLoading: false }));
    }

    render() {
        let posts = this.state.dataSet.map((post, index) => {
            function fPostHasImage(post, mode) {
                if (post.featured_media > 0){
                    let sourceUrl = post._embedded['wp:featuredmedia'][0].media_details.sizes.full.source_url;
                    let imageAlt = post._embedded['wp:featuredmedia'][0].media_details.sizes.full.alt;
                    if (mode === "sourceUrl")
                        return  sourceUrl;
                    else if (mode === "alt")
                        return  imageAlt;  
                }
            }
            let imageUrl = fPostHasImage(post, "sourceUrl");
            return (
                <div className="authorBubble" key={index}>
                    <PreloadImage
                        className="loading"
                        src={imageUrl} 
                        lazy
                        duration="1000ms"
                    />
                    <div className="authorName">
                        <p dangerouslySetInnerHTML={{__html: post.content.rendered}}></p>
                    </div>
                </div>
            );
        });
        if (this.state.isLoading) {
            return <Loading />;
        }
        return (
            <div>
                {posts}
            </div>
        );
    }
}

export default Author;