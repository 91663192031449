import React, { Component } from 'react';
import { BrowserRouter, Switch, Route, Link } from 'react-router-dom';
import HomePage from '../pages/HomePage';
import AboutPage from '../pages/AboutPage';
import BlogPage from './../pages/BlogPage';
import WorkPage from '../pages/WorkPage';
import ConsultingPage from '../pages/ConsultingPage';
import ContactPage from '../pages/ContactPage';
import PrivacyPage from '../pages/PrivacyPage';
import ImprintPage from '../pages/ImprintPage';
import i18n from '../i18n';
import { HelmetProvider } from 'react-helmet-async';

class Router extends Component {

    componentDidMount() {
        if(navigator.userAgent === 'ReactSnap') {
            // HIDE PRELOADED HTML TO REMOVE FLICKERING
            document.getElementById('App').style.setProperty("opacity", "0");
          }
    }

    render() {
        return (
            <BrowserRouter>
                <HelmetProvider>
                    <Switch>
                        <Route exact path='/' component={ HomePage }/>
                        <Route path='/about' component={ AboutPage }/>
                        <Route path='/blog' component={ BlogPage }/>
                        <Route path='/consulting' component={ ConsultingPage }/>
                        <Route path='/contact' component={ ContactPage }/>
                        <Route path='/coaching' component={ ConsultingPage }/>
                        <Route path='/work' component={ WorkPage }/>
                        <Route path='/privacy' component={ PrivacyPage }/>
                        <Route path='/imprint' component={ ImprintPage }/>
                    </Switch>
                </HelmetProvider>
            </BrowserRouter>
        );
    }
}

export default Router;