import React, { Component } from 'react';
import i18n from '../i18n';
import Cookies from 'universal-cookie';
import Analytics from 'analytics';
import googleAnalytics from '@analytics/google-analytics';

const cookies = new Cookies();

class CookieBanner extends Component {
    constructor(props) {
        super(props);
        this.state = { cookieBannerVisible: true, cookieAccept: false }
    }

    componentDidMount() {
        if (cookies.get('AcceptCookies')) {
            this.cookieValid();
        }
        else {
            //cookies.remove("_c_", { path: '/', domain: "localhost" });
            this.hideSite();
        }
    }

    hideSite() {
        document.getElementById('App').style.setProperty("filter", "blur0px)");
        document.getElementById('App').style.setProperty("height", "100vh");
        document.getElementById('App').style.setProperty("overflow", "hidden");
    }

    cookieValid() {
        this.setState({cookieBannerVisible: false, cookieAccept: true});
        document.getElementById('App').style.setProperty("height", "auto");
        document.getElementById('App').style.setProperty("overflow", "visible");
        document.getElementById('cookieContainer').style.setProperty("display", "none");
    }

    cookieAccept() {
        cookies.set('AcceptCookies', 'Confirmed', { path: '/', expires: new Date(Date.now() + 900000000) });
        this.setState({cookieAccept: true});
        document.getElementById('App').style.setProperty("height", "auto");
        document.getElementById('App').style.setProperty("overflow", "visible");
        document.getElementById('cookieContainer').style.setProperty("display", "none");
        // ACTIVATE ANALYTICS
        const analytics = Analytics({
            app: 'Work Home Flow',
            plugins: [
            googleAnalytics({
                trackingId: 'UA-167508217-1'
            })
            ]
        });
        analytics.page();
    }

    cookieReject() {
        this.setState({cookieAccept: false});
        document.getElementById('App').style.setProperty("height", "auto");
        document.getElementById('App').style.setProperty("overflow", "visible");
        document.getElementById('cookieContainer').style.setProperty("display", "none");
    }

    render() {
        if (this.state.cookieBannerVisible) {
            return (
                    <div>
                        <div className="cookieContainer" id="cookieContainer">
                            <div className="cookieBanner">
                                <div className="title">This website uses cookies</div>
                                <p className="desc">We use cookies to analyse our traffic with the Google Analytics services.</p>
                                <div className="btnAccept"  onClick={() => this.cookieAccept()}>Accept</div>
                                <div className="btnReject"  onClick={() => this.cookieReject()}>Reject</div>
                                <p className="links"><a href="/privacy">Read our privacy policy.</a></p>
                            </div>
                        </div>
                    </div>
            );
        }
        else {
            return false;
        }
    }
}

export default CookieBanner;