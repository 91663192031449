import React, { Component } from 'react';
import Loading from './Loading';
import i18n from '../i18n';

const API = 'https://willcer.com/admin/wp-json/wp/v2/';
const QUERY = 'pages/?slug=digital-work';

class Work extends Component {
    constructor(props) {
        super(props);
        this.state = { dataSet: [], isLoading: true }
    }
    
    componentDidMount() {
        let addLang = "";
        let lang = i18n.language.substr(0,2); 
        if (lang === "fr") {
            addLang = "-fr";
        }fetch(API+QUERY+addLang)
            .then(response => {
                if  (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Data unavailbale.');
                }
            })
        .then(response => { this.setState({ dataSet: response, isLoading: false }) })
        .catch(error => this.setState({ error, isLoading: false }));
    }

    render() {
        let title = this.state.dataSet.map((data) => { return ( data.title.rendered )});
        let content = this.state.dataSet.map((data) => { return ( data.content.rendered )});
        if (this.state.isLoading) {
            return <Loading />;
        }
        return (
            <div className="blockFull">
                <div className="blockLeft">
                    <div className="anchor" id="work"><u className="flash">{title}</u></div>
                </div>
                <div className="blockText">
                    <p dangerouslySetInnerHTML={{__html: content}}></p>
                </div>
            </div>
        );
    }
}

export default Work;