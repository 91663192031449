import React, { Component } from 'react';
import Author from './../components/Author';
import Loading from './../components/Loading';
import PreloadImage from 'react-preload-image';
import i18n from '../i18n';
import { Helmet } from 'react-helmet-async';

const API = 'https://willcer.com/admin/wp-json/wp/v2/';
const QUERY = 'posts?_embed&filter[orderby]=menu_order&order=asc';

class BlogPost extends Component {
    constructor(props) {
        super(props);
        this.state = { slug: this.props.slug, dataSet: [], isLoading: false }
    }
    
    componentDidMount() {
        this.setState({ isLoading: true });
        let addQuery = "";
        if (this.state.slug !== "")
            addQuery = "&slug="+this.state.slug;
        fetch(API+QUERY+addQuery)
            .then(response => {
                if  (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Data unavailbale.');
                }
            })
        .then(response => { this.setState({ dataSet: response, isLoading: false }) })
        .catch(error => this.setState({ error, isLoading: false }));
    }

    truncateContent(content, numWords) {
        return content.split(" ").splice(0,numWords).join(" ")+"...";
    }

    render() {
        const { dataSet, isLoading, error } = this.state;
        if (error) {
            return <p>{error.message}</p>;
        }
        if (isLoading) {
            return <Loading />;
        }
        let posts = dataSet.map((post, index) => {
            function fPostHasImage(post, mode) {
                if (post.featured_media > 0){
                    let sourceUrl = post._embedded['wp:featuredmedia'][0].media_details.sizes.medium_large.source_url;
                    let imageAlt = post._embedded['wp:featuredmedia'][0].media_details.sizes.medium_large.alt;
                    if (mode === "sourceUrl")
                        return  sourceUrl;
                    else if (mode === "alt")
                        return  imageAlt;  
                }
            }
            let imageUrl = fPostHasImage(post, "sourceUrl");
            return (
                <div className="post" key={index}>
                    <Helmet> 
                        <meta lang={i18n.language.substr(0,2)} /> 
                        <title>{i18n.t('meta site title')} {i18n.t('meta site title blog')} – {post.title.rendered}</title>
                    </Helmet>
                     {/*<a className="btnBack" href='/blog'>← All Articles</a>*/}
                    <div className="text">
                        <h1><u>{post.title.rendered}</u></h1>
                        <div className="excerpt" dangerouslySetInnerHTML={{__html: post.excerpt.rendered }}></div>
                        <p dangerouslySetInnerHTML={{__html: post.content.rendered}}></p>
                        < Author />
                    </div>
                    <div className="image">
                        <div className="box">
                            <PreloadImage
                                className="loading"
                                src={imageUrl} 
                                lazy
                                duration="1000ms"
                            />
                        </div>
                    </div>
                </div>
            )
        });
        return (
                <div className="blogPost">
                    <div className="posts">
                        <div className="list">
                            { posts }
                        </div>
                    </div>
                </div>
        );
    }
}

export default BlogPost;