import React, { Component } from 'react';

class Logo extends Component {
    constructor(props) {
        super(props);
        this.state = { menuMobile: false, loc: this.props.loc }
    }

    render() {
        return (  
            <div id="logo"><a href='/'><span>W</span><b>illcer</b></a></div>
        );
    }
}

export default Logo;