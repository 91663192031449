import React, { Component } from 'react';
import Navigation from '../components/Navigation';
import Logo from '../components/Logo';
import Footer from '../components/Footer';
import ContactBanner from '../components/ContactBanner';
import Projects from '../components/Projects';
import Work from '../components/Work';
import Project from '../components/Project';
import i18n from '../i18n';
import { Helmet } from 'react-helmet-async';

class WorkPage extends Component {

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        let pathArray = window.location.pathname.split('/');
        let slug = pathArray[2];
        if (slug === undefined || slug === "") {
            return (
                <div>
                    <Helmet>
                        <title>{i18n.t('meta site title')} {i18n.t('meta site title work')}</title>
                    </Helmet>
                    <div className="wrapper">
                        <Navigation />
                        <Logo />
                    </div>
                    <div className="wrapperSqueeze">
                        <div className="work projectsPage">
                            <div className="sectionTitle">
                                <div className="anchor" id="work"><u className="flash">{i18n.t('digital work')}</u></div>
                            </div>
                            <Projects />
                            <div className="spacer"></div>
                            <div className="spacerHalf"></div>
                            <Work />
                        </div>
                    </div>
                    <div className="spacer"></div>
                    <ContactBanner />
                    < Footer />
                </div>
            );
        }
        else {
            return (
                <div>
                    <div className="wrapper">
                        <Navigation />
                        <Logo />
                    </div>
                    <div className="wrapperSqueeze">
                        <Project slug={slug} />
                    </div> 
                    <div className="spacer"></div>
                    <ContactBanner />
                    < Footer />
                </div>
            );
        }
    }
}

export default WorkPage;