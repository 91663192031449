import React, { Component } from 'react';
import Loading from './Loading';
import PreloadImage from 'react-preload-image';
import i18n from '../i18n';

const API = 'https://willcer.com/admin/wp-json/wp/v2/';
const QUERY = 'testimonial?_embed&filter[orderby]=menu_order';

class Testimonials extends Component {
    constructor(props) {
        super(props);
        this.state = { dataSet: [], isLoading: false, currentObj: 0 }
    }

    componentDidMount() {
        this.setState({ isLoading: true });
        fetch(API+QUERY)
            .then(response => {
                if  (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Data unavailbale.');
                }
            })
        .then(response => { this.setState({ dataSet: response, isLoading: false }) })
        .catch(error => this.setState({ error, isLoading: false }));
        this.intervalId = setInterval(this.animateAlternateObject.bind(this), 8900);
    }

    componentWillUnmount() {
        clearInterval(this.intervalId);
        clearTimeout(this.timeoutId);
        clearTimeout(this.timeoutId2);
    }

    animateAlternateObject() {
        let maxLimitObj = this.state.dataSet.length -1 ;
        
        if (this.state.currentObj === maxLimitObj) {
            this.timeoutId =  setTimeout(this.fadeOut(this.state.currentObj), 0);
            this.setState({ currentObj: 0});
            this.timeoutId =  setTimeout(this.fadeIn(this.state.currentObj), 0);
        }
        else if (this.state.currentObj === 0) {
            this.setState({ currentObj: this.state.currentObj + 1});
            this.timeoutId =  setTimeout(this.fadeOut(this.state.currentObj - 1), 0);
            this.timeoutId =  setTimeout(this.fadeIn(this.state.currentObj), 0);
        }
        else if (this.state.currentObj > 0) {
            this.setState({ currentObj: this.state.currentObj + 1});
            this.timeoutId =  setTimeout(this.fadeOut(this.state.currentObj - 1), 0);
            this.timeoutId =  setTimeout(this.fadeIn(this.state.currentObj), 0);
            
        }
       
        console.log(this.state.currentObj);
    }

    selectObject(myObject) {
        let maxLimitObj = this.state.dataSet.length -1 ;
        clearInterval(this.intervalId);
        clearTimeout(this.timeoutId);
        this.setState({ currentObj: myObject});
        this.intervalId = setInterval(this.animateAlternateObject.bind(this), 8900);
        for (var i = 0; i <= maxLimitObj; i++) {
            this.fadeOut(i);
        }
        this.fadeIn(myObject);
    }

    fadeIn(myObject) {
        document.getElementById("obj"+myObject).classList.remove("myFadeOut");
        document.getElementById("obj"+myObject).classList.add("myFadeIn");
        document.getElementById("dot"+myObject).classList.add("active");
        document.getElementById("quoteSign").classList.add("animate");
        this.timeoutId2 = setTimeout(function(){ document.getElementById("quoteSign").classList.remove("animate");}, 6000);
    }

    fadeOut(myObject) {
        document.getElementById("obj"+myObject).classList.remove("myFadeIn");
        document.getElementById("obj"+myObject).classList.add("myFadeOut");
        document.getElementById("dot"+myObject).classList.remove("active");
    }

    render() {
        let posts = this.state.dataSet.map((post, index) => {
            function fPostHasImage(post, mode) {
                if (post.featured_media > 0)  {
                    let sourceUrl = post._embedded['wp:featuredmedia'][0].source_url;
                    if (mode === "sourceUrl")
                        return  sourceUrl;
                }
            }
            let imageUrl = fPostHasImage(post, "sourceUrl");
            let content = post.content.rendered;
            let lang = i18n.language.substr(0,2); 
            if (lang === "fr") {
                content = post.acf.content_fr;
            }
            return (
                <div className="testimonial" id={"obj"+index} key={index}>
                    <div className="content" dangerouslySetInnerHTML={{__html: content}}></div>
                    <div className="byLine" dangerouslySetInnerHTML={{__html: post.title.rendered}}></div>
                    <PreloadImage
                        className="loading"
                        src={imageUrl} 
                        lazy
                        duration="1000ms"
                    />
                </div>
            );
        });

        let navigation = this.state.dataSet.map((post, index) => {
            let addStyle;
            if (index === 0)
                addStyle = "active";
            return (
                <div onClick={() => this.selectObject(index)} className={"dot "+addStyle} id={"dot"+index}  key={index}>•</div>
            );
        });
        if (this.state.isLoading) {
            return <Loading />;
        }

        return (
            <div className="testimonials" id="myTestimonials">
                <div className="myContent">
                    <div className="navigation">{navigation}</div>
                </div>
                {posts}
                <div className="quoteSign" id="quoteSign">“</div>
            </div>
        );
    }
}

export default Testimonials;