import React, { Component } from 'react';
import i18n from '../i18n';
import { NavLink } from 'react-router-dom';

class Navigation extends Component {
    constructor(props) {
        super(props);
        this.state = { menuMobile: false, mode: this.props.mode };
        this.fadeMenuIn = this.fadeMenuIn.bind(this);
    }

    fadeMenuIn() {
        if (window.innerWidth <= 1015) {
            this.setState({ menuMobile: !this.state.menuMobile})
            if(this.state.menuMobile) {
                this.fadeMenuOut()
                document.getElementById('btnHamburger').style.setProperty("font-size", "32px");
                document.getElementById('App').style.setProperty("height", "auto");
                document.getElementById('App').style.setProperty("overflow", "visible");
                document.getElementById('btnHamburger').innerText = "☰";
                document.getElementById('nav').style.setProperty("top", "-2000px");
                document.getElementById('btnHamburger').style.setProperty("color", "#333");
            }
            else {
                document.getElementById('menuList').style.setProperty("opacity", "1");
                document.getElementById('App').style.setProperty("height", "80vh");
                document.getElementById('App').style.setProperty("overflow", "hidden");
                document.getElementById('nav').style.setProperty("top", "0px");
                document.getElementById('btnHamburger').style.setProperty("font-size", "24px");
                document.getElementById('btnHamburger').innerText = "✕";
                document.getElementById('btnHamburger').style.setProperty("color", "#333");
                //document.getElementById('btnHamburger').style.setProperty("border-color", "#333");
            }
        }
    }

    fadeMenuOut() {
        if (window.innerWidth <= 1015) {
            document.getElementById('menuList').style.setProperty("opacity", "0");
            document.getElementById('nav').style.setProperty("top", "-2000px");
            document.getElementById('btnHamburger').style.setProperty("font-size", "32px");
            document.getElementById('App').style.setProperty("height", "auto");
            document.getElementById('App').style.setProperty("overflow", "auto");
            document.getElementById('btnHamburger').style.setProperty("color", "#333");
            //document.getElementById('btnHamburger').style.setProperty("border-color", "#333");
            document.getElementById('btnHamburger').innerText = "☰";
        }
    }

    changeLang(lang) {
        document.getElementById('App').style.setProperty("opacity", "0");
        window.location.reload(false);
        i18n.changeLanguage(lang);
    }
    
    render() {
        return (
                <div>
                    <div className="btnHamburger" id="btnHamburger" onClick={() => this.fadeMenuIn()} >☰</div>
                    <nav className="menuTop" id="nav">
                        <ul className="menuList" id="menuList">
                            <li onClick={() => this.fadeMenuOut()}>
                                <NavLink to='/about'>{i18n.t('about')}</NavLink>
                            </li>
                            {/* <li onClick={() => this.fadeMenuOut()}>
                                <NavLink to='/consulting'>{i18n.t('coaching')}</NavLink>
                            </li> */}
                            <li onClick={() => this.fadeMenuOut()}>
                                <NavLink to='/work'>{i18n.t('digital work')}</NavLink>
                            </li>
                            <li onClick={() => this.fadeMenuOut()}>
                                <NavLink to='/contact'>{i18n.t('contact')}</NavLink>
                            </li>
                            {/* <li onClick={() => this.fadeMenuOut()}>
                                <NavLink to='/blog'>{i18n.t('blog')}</NavLink>
                            </li> */}
                            <li onClick={() => this.fadeMenuOut()}>
                                <div href="#" className="langSel" onClick={() => this.changeLang(i18n.t('other language code'))}>{i18n.t('other language')}</div>
                            </li>
                        </ul>
                    </nav>
                </div>
        );
    }
}

export default Navigation;