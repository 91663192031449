import React, { Component } from 'react';
import Author from './Author';
import iconIn from './../assets/iconIn.png';
import iconInsta from './../assets/iconInsta.png';
import iconMed from './../assets/iconMed.png';

class ContactButton extends Component {

    render() {
         return (
            <div className="contactButton">
                <a href="mailto:nicolas@willcer.com" target="_blank" rel="noopener noreferrer"><Author /></a>
                <a href="https://www.linkedin.com/in/nicolashuart/" target="_blank" rel="noopener noreferrer"><img src={iconIn} alt="Linkedin"/></a>
                {/* <a href="https://www.instagram.com/_willcer/" target="_blank" rel="noopener noreferrer"><img src={iconInsta} alt="Willcer"/></a>
                <a href="https://medium.com/@nicolashuart/" target="_blank" rel="noopener noreferrer"><img src={iconMed} alt="Medium"/></a> */}
            </div>
        );
    }
}

export default ContactButton;