import React, { Component } from 'react';
import Loading from './../components/Loading';
import PreloadImage from 'react-preload-image';
import i18n from '../i18n';

const API = 'https://willcer.com/admin/wp-json/wp/v2/';
const QUERY = 'projects?_embed&filter[orderby]=menu_order&order=asc';

class Projects extends Component {
    constructor(props) {
        super(props);
        this.state = { dataSet: [], isLoading: false}
    }
    
    componentDidMount() {
        this.setState({ isLoading: true });
        fetch(API+QUERY)
            .then(response => {
                if  (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Data unavailbale.');
                }
            })
        .then(response => { this.setState({ dataSet: response, isLoading: false }) })
        .catch(error => this.setState({ error, isLoading: false }));
    }

    render() {
        const { dataSet, isLoading, error } = this.state;
        if (error) {
          return <p>{error.message}</p>;
        }
        if (isLoading) {
            return <Loading />;
        }
        let mainUrl = "work/";
        let projects = dataSet.map((project, index) => {
            function fPostHasImage(project, mode) {
                if (project.featured_media > 0){
                    let sourceUrl = project._embedded['wp:featuredmedia'][0].media_details.sizes.large.source_url;
                    let imageAlt = project._embedded['wp:featuredmedia'][0].media_details.sizes.large.alt;
                    if (mode === "sourceUrl")
                        return  sourceUrl;
                    else if (mode === "alt")
                        return  imageAlt;  
                }
            }
            let imageUrl = fPostHasImage(project, "sourceUrl");
            let title = project.title.rendered;
            let excerpt = project.excerpt.rendered;
            let lang = i18n.language.substr(0,2); 
            if (lang === "fr") {
                title = project.acf.title_fr;
                excerpt = project.acf.excerpt_fr;
            }
            return (
                    <div className="project" key={index}>
                        <div className="banner">
                            <a href={mainUrl+project.slug}>
                                <PreloadImage
                                    className="loading"
                                    src={imageUrl} 
                                    lazy
                                    duration="1000ms"
                                />
                            </a>
                        </div>
                        <div className="content">
                            <div className="text">
                                <a href={mainUrl+project.slug}><h1  dangerouslySetInnerHTML={{__html: title}}></h1></a>
                                <a href={mainUrl+project.slug}><p className="excerpt" dangerouslySetInnerHTML={{__html: excerpt}}></p></a>
                            </div>
                        </div>
                    </div>
            )
        });
        return (
            <div className="projects">
                { projects }
            </div>
    );
    }
}

export default Projects;