import React, { Component } from 'react';
import Navigation from '../components/Navigation';
import Logo from '../components/Logo';
import Footer from '../components/Footer';
import Consulting from '../components/ConsultingNew';
import ContactBanner from '../components/ContactBanner';
import i18n from '../i18n';
import { Helmet } from 'react-helmet-async';

class CoachingPage extends Component {

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <div>
                <Helmet>
                    <title>{i18n.t('meta site title')} {i18n.t('meta site title coaching')}</title>
                </Helmet>
                <div className="wrapper">
                    <Navigation />
                    <Logo />
                </div>
                <Consulting />
                <ContactBanner />
                <Footer />
            </div>
        );
    }
}

export default CoachingPage;