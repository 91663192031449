import React, { Component } from 'react';
import Loading from './Loading';
import Questions from './Questions.js';
import Author from './Author.js';
import i18n from '../i18n';
import rocket from '../assets/rocketFill.png';

const API = 'https://willcer.com/admin/wp-json/wp/v2/';
const QUERY = 'pages/?_embed&slug=coaching';

class ConsultingNew extends Component {
    constructor(props) {
        super(props);
        this.state = { dataSet: [], isLoading: true }
    }
    
    componentDidMount() {
        let addLang = "";
        let lang = i18n.language.substr(0,2); 
        if (lang === "fr") {
            addLang = "";
        }fetch(API+QUERY+addLang)
            .then(response => {
                if  (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Data unavailbale.');
                }
            })
        .then(response => { this.setState({ dataSet: response, isLoading: false }) })
        .catch(error => this.setState({ error, isLoading: false }));
    }

    render() {
        if (this.state.isLoading) {
            return <Loading />;
        }
        let page = this.state.dataSet.map((data, index) => {
            let content = data.content.rendered;
            let bio = data.acf.bio_consulting;
            let resources = data.acf.resources_consulting;
            return (
                <div className="" key={index}>
                <Questions/>
                <svg className="wave">
                    <clipPath id="wave" clipPathUnits="objectBoundingBox">
                    <path d="M1,0c0,0-0.3,0.1-0.5,0.1S0.3,0,0,0.1V1h1L1,0z"/>
                    </clipPath>
                </svg>
                <div className="coaching" key={index}>
                <div className="content" dangerouslySetInnerHTML={{__html: content}}></div>
                <div className="btnContact"><a href="mailto:nicolas@willcer.com?subject=Ordering Your Free 1-Hour Session" target="_blank" rel="noopener noreferrer">Get your free 1-hour session!</a></div>
                <div className="contentBox">
                    <Author/>
                    <div className="col" dangerouslySetInnerHTML={{__html: bio}}></div>
                </div>
                <div className="contentBoxDark">
                    <div className="content" dangerouslySetInnerHTML={{__html: resources}}></div>
                </div>
                <div className="btnContact"><a href="mailto:nicolas@willcer.com?subject=Ask me antyhing!" target="_blank" rel="noopener noreferrer">Ask me anything!</a></div>
                <br/><br/>
                </div>
                <div className="rocket" id="rocket">
                    <div className="icon">“</div>
                </div>
            </div>
            );
        });
        return (
            <div>
                { page }
            </div>
        );
    }
}

export default ConsultingNew;